<template>
    <v-container>
        <myPurposesList />
        <nextPostBtn :url-next-post="nextPost" />
    </v-container>
</template>

<script>

import myPurposesList from '@/components/purpose/myPurposesList'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'
export default {
    name: 'MyPurposeView',
    components: { myPurposesList, nextPostBtn },
    mixins: [postMixin]
}
</script>

<style scoped>

</style>
