<template>
    <v-row>
        <v-col
            cols="12"
        >
            <v-row class="mb-2 mx-2">
                <span style="font-size: large">{{ $t('purposes.myPurposeCloud') }}</span>
            </v-row>

            <transition-group
                :name="purposeCloud ? 'slide-fade-y-down' : 'slide-fade-y-up'"
                tag="p"
            >
                <v-card
                    v-if="purposeCloud"
                    :key="1"
                    color="dark"
                    dark
                    class="rounded-xl"
                >
                    <v-icon
                        style="position: absolute; right: 20px; top:  -35px;"
                        color="secondary mt-1"
                        dark
                        size="60px"
                        @click="openStatistic"
                    >
                        mdi-chart-box-plus-outline
                    </v-icon>

                    <purposeText :purpose="purposeCloud" />

                    <v-divider />

                    <v-row>
                        <v-col
                            class="col-auto pl-6"
                        >
                            <purpose-date :purpose="purposeCloud" />
                        </v-col>

                        <v-spacer />
                        <v-col
                            class="mx-2 my-0"
                            style="display: flex; justify-content: flex-end"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon

                                        class="mr-2"
                                        v-bind="attrs"
                                        @click="editItem()"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>

                                <span>{{ $t('common.edit') }}</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon

                                        v-bind="attrs"
                                        @click="deleteItem()"
                                        v-on="on"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>

                                <span>{{ $t('common.delete') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <!--              Showing the like and dislike quantity of the purpose cloud-->
                    <!--
                                      <v-divider />

                                     <v-row>
                                          <v-col
                                              class="col-auto pl-6 ml-6"
                                          >
                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      <v-icon
                                                          class="mr-2"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                      >
                                                          mdi-thumb-up
                                                      </v-icon>

                                                      {{ purposeCloud.attributes.likes || 0 }}
                                                  </template>

                                                  <span>{{ $t('purposes.likes') }}</span>
                                              </v-tooltip>
                                          </v-col>

                                          <v-spacer />

                                          <v-col
                                              class="col-auto ml-2 mr-6 pr-6 my-0"
                                              style="display: flex; justify-content: flex-end"
                                          >
                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      {{ purposeCloud.attributes.dislikes || 0 }}

                                                      <v-icon
                                                          class="ml-2"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                      >
                                                          mdi-thumb-down
                                                      </v-icon>
                                                  </template>

                                                  <span>{{ $t('purposes.dislikes') }}</span>
                                              </v-tooltip>
                                          </v-col>
                                      </v-row>-->
                </v-card>

                <v-card
                    v-else
                    :key="2"
                    color="dark"
                    dark
                    class="rounded-xl"
                    height="100px"
                >
                    <v-icon
                        style="position: absolute; right: 20px; top:  -20px;"
                        color="secondary mt-2"
                        dark
                        size="60px"
                    >
                        mdi-cloud-off-outline
                    </v-icon>

                    <v-card-subtitle class="text-h7 pt-8">
                        <span class="text-h7 ma-0 pa-0">{{ $t('purposes.purposeCloudEmpty') }}</span>
                    </v-card-subtitle>
                </v-card>
            </transition-group>
        </v-col>
        <confirm-dialog
            ref="confirm"
        />
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import purposeText from '@/components/purpose/purposeText'
import purposeDate from '@/components/purpose/purposeDate'

export default {
    name: 'PurposeCloud',
    components: { ConfirmDialog, purposeText, purposeDate },
    props: {
        purposeCloud: {
            type: Object,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            urlMain: '/purposesCloud'
        }
    },
    created() {
        this.fetchPurposeCloud();
    },
    methods: {
        async deleteItem(item = this.purposeCloud) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('purposes.confirmDeleteMision')
                )
            ) {
                this.$emit('delete:purposeCloud', item);
            }
        },
        async fetchPurposeCloud() {
            try {
                let myPurposeCloud = await axios
                    .get(this.urlMain, {
                        params: {
                            'filter[my]': 1
                        }
                    });

                /* console.log('myPurposeCloud.data.data')
                console.log(myPurposeCloud.data.data) */
                myPurposeCloud = myPurposeCloud.data.data && myPurposeCloud.data.data.length ? myPurposeCloud.data.data[0] : null;

                this.$emit('update:purposeCloud', myPurposeCloud);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        editItem() {
            const item = JSON.stringify(this.purposeCloud);
            this.$router.push({ name: 'PurposeCreate', params: { item, isPurposeCloud: 'true' } });
        },
        openStatistic() {
            this.$router.push('/app/pages/statistics');
        }
    }
}
</script>

<style scoped>

</style>
