<template>
    <v-container
        fluid
        class="pt-0 mx-0 px-0"
    >
        <!--      Purpose Cloud-->
        <myPurposeCloud
            :purpose-cloud="purposeCloud"
            @delete:purposeCloud="deletePurposeCloudConfirmed"
            @update:purposeCloud="purposeCloud = $event"
        />

        <!--      Add purpose-->
        <v-card
            class="my-6 rounded-lg"
            style="position: relative"
            outlined
        >
            <v-card-title class="text-h6 my-0 py-0">
                {{ $t('purposes.myDrafts') }}
            </v-card-title>

            <v-fab-transition>
                <v-btn
                    v-show="true"
                    color="secondary mt-2"
                    dark
                    absolute
                    top
                    right
                    fab
                    @click="createPurpose"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>

            <v-row>
                <v-col class="px-6">
                    <!--            <v-btn
                class="mx-2 my-2"
                fab
                dark
                color="secondary"
                @click="createPurpose"
            >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>-->

                    <!--      Purposes List-->
                    <!--      :search="search"-->
                    <v-data-iterator
                        :items="dataFilter"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                        no-data-text="No ha creado ningun proposito"
                    >
                        <template v-slot:header>
                            <v-toolbar
                                v-if="$vuetify.breakpoint.mdAndUp"
                                dark
                                color="primary"
                                class="mb-1 mt-3 rounded-lg"
                            >
                                <v-text-field
                                    v-model="search"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :label="$t('common.search')"
                                />

                                <template>
                                    <!--                        <v-spacer />
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-magnify"
                            label="Sort by"
                        />-->
                                    <v-spacer />
                                    <v-btn-toggle
                                        v-model="sortDesc"
                                        mandatory
                                        class="ml-2"
                                    >
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="false"
                                        >
                                            <v-icon>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="true"
                                        >
                                            <v-icon>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-toolbar>
                        </template>

                        <template v-slot:default="props">
                            <v-row>
                                <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <v-card
                                        color="dark"
                                        dark
                                        class="rounded-xl"
                                    >
                                        <purposeText :purpose="item" />

                                        <v-divider />

                                        <v-row>
                                            <v-col
                                                class="col-auto pl-6"
                                            >
                                                <purpose-date :purpose="item" />
                                            </v-col>

                                            <v-spacer />
                                            <v-col
                                                class="mx-2 my-0"
                                                style="display: flex; justify-content: flex-end"
                                            >
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon

                                                            class="mr-2"
                                                            v-bind="attrs"
                                                            @click="editItem(item)"
                                                            v-on="on"
                                                        >
                                                            mdi-pencil
                                                        </v-icon>
                                                    </template>

                                                    <span>{{ $t('common.edit') }}</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon

                                                            v-bind="attrs"
                                                            @click="deleteItem(item)"
                                                            v-on="on"
                                                        >
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>

                                                    <span>{{ $t('common.delete') }}</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            large
                                                            class="ml-2 mr-1"
                                                            v-bind="attrs"
                                                            color="secondary"
                                                            @click="uploadPurpose(item)"
                                                            v-on="on"
                                                        >
                                                            mdi-cloud-upload
                                                        </v-icon>
                                                    </template>

                                                    <span>{{ $t('purposes.upToCloud') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:no-data>
                            <div class="d-flex flex-column align-center text-gray-600 mt-6">
                                <span class="text-h6">
                                    {{ $t('purposes.howToCreatePurposes') }}
                                </span>

                                <div class="d-flex flex-column align-center text-subtitle-1">
                                    <span>{{ $t('purposes.createMissionDraftInit') }}  </span>
                                    <v-fab-transition>
                                        <v-btn
                                            color="secondary"
                                            dark
                                            fab
                                            @click="createPurpose"
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-fab-transition>

                                    <span v-html="$t('purposes.createMissionDraftEnd')" />
                                    <v-img
                                        src="@/assets/img/purposes/cloud.png"
                                        contain
                                        width="56px"
                                    />
                                </div>
                            </div>
                        </template>
                        <template
                            v-if="items && items.length"
                            v-slot:footer
                        >
                            <v-row
                                class="mt-2 ml-2 mr-1"
                                align="center"
                                justify="center"
                            >
                                <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            text
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ itemsPerPage }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(number, index) in itemsPerPageArray"
                                            :key="index"
                                            @click="updateItemsPerPage(number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer />

                                <span
                                    class="mr-4 grey--text"
                                >
                                    {{ $t('common.page') + " " + page + " " + $t('common.of') + " " + numberOfPages }}
                                </span>
                                <div>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="mr-1"
                                        @click="formerPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-1 mr-2"
                                        @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </v-card>

        <v-row>
            <v-col>
                <span
                    class="text-gray-600 text-h6"
                    v-html="$t('purposes.help.title')"
                />

                <div
                    class="text-gray-600"
                    style="display: grid; grid-template-columns: 60px auto; row-gap: 15px; column-gap: 5px; align-items: center"
                >
                    <v-fab-transition>
                        <v-btn
                            color="secondary"
                            dark
                            fab
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                    <span v-html="$t('purposes.help.add')" />

                    <v-img
                        src="@/assets/img/purposes/cloud.png"
                        contain
                        width="56px"
                    />
                    <span v-html="$t('purposes.help.cloud')" />

                    <div style="display: flex; background-color: #28344a; border-radius: 10%; margin: 5px 3px">
                        <v-icon
                            class="mr-2"
                            dark
                        >
                            mdi-pencil
                        </v-icon>

                        <v-icon
                            dark
                        >
                            mdi-delete
                        </v-icon>
                    </div>
                    <span v-html="$t('purposes.help.editDelete')" />

                    <v-icon
                        color="secondary"
                        dark
                        size="60px"
                    >
                        mdi-chart-box-plus-outline
                    </v-icon>
                    <span v-html="$t('purposes.help.statistic')" />
                </div>
            </v-col>
        </v-row>

        <confirm-dialog
            ref="confirm"
        />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import myPurposeCloud from '@/components/purpose/myPurposeCloud'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import purposeText from '@/components/purpose/purposeText'
import purposeDate from '@/components/purpose/purposeDate'
import { mapGetters } from 'vuex'
export default {
    name: 'PurposesList',
    components: { myPurposeCloud, ConfirmDialog, purposeText, purposeDate },
    data() {
        return {
            urlMain: '/purposes',
            items: [], // purposes
            itemSelected: null,
            itemsPerPageArray: [2, 4, 8],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'attributes.updated-at',
            purposeCloud: null
        }
    },
    computed: {
        ...mapGetters(['getMyGroups']),
        dataFilter() {
            const search = this.search;

            if (!search) return this.items;

            return this.items.filter(child => (child.attributes.body && child.attributes.body.toUpperCase().includes(search.toUpperCase())) ||
                (child.attributes['created-at'] && this.dateHelper.getDateTimeTableFormat(child.attributes['created-at']).includes(search)) ||
                (child.attributes['updated-at'] && this.dateHelper.getDateTimeTableFormat(child.attributes['updated-at']).includes(search))
            );
        },
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        }
        /* filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        } */
    },
    mounted() {
        this.fetchPurpose();
    },
    methods: {
        async uploadPurpose(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('purposes.confirmUpload')
                )
            ) {
                this.uploadPurposeConfirmed(item)
            }
        },
        async uploadPurposeConfirmed(item) {
            if (this.getMyGroups.length) {
                const group = this.getMyGroups[0];

                var dataSubmit = {
                    data: {
                        type: 'purposesCloud',
                        attributes: {
                            group_id: group,
                            body: item.attributes.body,
                            body2: item.attributes.body2,
                            body3: item.attributes.body3,
                            order: 100
                        }
                    }
                };

                const method = 'post'

                try {
                    const result = await axios({
                        method: method,
                        url: 'purposesCloud',
                        data: dataSubmit
                    });

                    if (result.status === 200 || result.status === 201 || result.status === 204) {
                        // this.viewPurpose(result.data.data)
                        this.purposeCloud = result.data.data;
                        this.$store.dispatch('snackbar/success', this.$t('purposes.upToCloudOk'));
                    }
                } catch (error) {
                    if (error.response.status !== 423) { // Error 423 is that the competition close and is managed globally
                        if (error.response.status === 403) {
                            this.$store.dispatch('snackbar/error', this.$t('groups.groupsProblemNeedtoLoging'));
                        } else { this.$store.dispatch('snackbar/error', this.$t('common.accionError')); }
                    }
                }
            } else {
                this.$store.dispatch('snackbar/error', this.$t('groups.needToHaveAgroup'));
            }
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchPurpose();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                /* if (error.response) {
              console.log(error.response.data.error) // "invalid_credentials"
              console.log(error.response.status)
              console.log(error.response.headers)
          } */
            }
            this.$store.dispatch('loading/end')
        },
        async fetchPurpose() {
            try {
                this.$store.dispatch('loading/start');

                const purposes = await axios
                    .get(this.urlMain, {
                        params: {
                            'filter[my]': 1
                        }
                    });

                this.initialize(purposes.data);
            } catch (e) {
                console.log('Error: ')
                console.log(e)
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                this.$store.dispatch('loading/end')
            }
        },
        initialize($data) {
            // console.log(items)
            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0

            this.items = $data.data;
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        editItem(item) {
            this.$router.push({ name: 'PurposeCreate', params: { item: JSON.stringify(item) } });
        },
        createPurpose() {
            this.$router.push({ name: 'PurposeCreate' });
        },

        async deletePurposeCloudConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete("/purposesCloud" + urlAppend);

                if (result.status === 204) {
                    this.purposeCloud = null;
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
            }
            this.$store.dispatch('loading/end')
        }
    }
}
</script>

<!--<style >
.ql-align-right{
  text-align: right !important;
}
</style>-->
